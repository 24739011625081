import endpoints from '../utils/apiClient/endpoints';

export interface UserSummary {
    userId: number;
    email: string;
    userName: string;
    userPrincipalName: string;
    userType: string;
    displayName: string;
}

export async function searchUser(search: string, count: number): Promise<UserSummary[]> {
    const response = await endpoints.users.search.get({
        queryParameters: {
            search,
            count,
        },
    });

    return await response.json();
}
