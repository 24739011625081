//TODO: Refactor the usage, avoid creating graphics, graphic layers etc
import Graphic from '@arcgis/core/Graphic';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './index';
import { clearPresentation } from './presentationSlice';

interface OsmLayerSliceState {
    selectedGraphics: Graphic[];
    selectedPin: Graphic | undefined;
}

const initialState: OsmLayerSliceState = {
    selectedGraphics: [] as Graphic[],
    selectedPin: undefined,
};

export const osmLayerSlice = createSlice({
    name: 'osmLayer',
    initialState,
    reducers: {
        setSelectedOsmGraphics(state, action: PayloadAction<Graphic[]>) {
            state.selectedGraphics = action.payload;
        },
        setSelectedPin(state, action: PayloadAction<Graphic>) {
            state.selectedPin = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectedOsmGraphics = (state: RootState): Graphic[] => {
    return state.osmLayer?.selectedGraphics;
};

export const selectedPin = (state: RootState): Graphic | undefined => {
    return state.osmLayer?.selectedPin;
};

export const { setSelectedOsmGraphics, setSelectedPin } = osmLayerSlice.actions;

export default osmLayerSlice.reducer;
