import { CSSProperties, ReactElement } from 'react';
import type { AvatarProps } from '@jll/react-ui-components';
import { Avatar } from '@jll/react-ui-components';

import { stringToColor } from 'utils/colorUtils';
import { buildUserInitialsFromDisplayName } from 'utils/userUtils';
export interface UserAvatarProps {
    fullName: string;
    photo?: string;
    style?: CSSProperties;
    size?: 'large' | 'small' | 'default' | number | undefined;
}

export function UserAvatar({
    fullName,
    photo,
    style,
    size = 'large',
}: UserAvatarProps): ReactElement {
    const props: AvatarProps = {
        size,
    };

    if (photo) {
        props.src = photo;
    } else {
        const userInitials = buildUserInitialsFromDisplayName(fullName);
        const avatarColor = stringToColor(fullName);
        props.style = { backgroundColor: avatarColor, ...style };
        props.children = userInitials;
    }

    return <Avatar {...props} />;
}
