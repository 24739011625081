import {
    createAction,
    createSelector,
    createSlice,
    isAnyOf,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from 'store';
import { primaryLibraryItemKey } from 'types/Layers/LibraryLayerTreeItem';
import { enterPresentationMode } from './applicationModeSlice';
import { clearPresentation } from './presentationSlice';

interface InactiveMatchState {
    stateName: 'INACTIVE';
}

interface SelectMatchState {
    stateName: 'SELECT_MATCH';
    csvItemKey: string;
}

interface ConfirmMatchState {
    stateName: 'CONFIRM_MATCH';
    csvItemKey: string;
    candidateMatchOsmId: number;
}

interface ViewMatchState {
    stateName: 'VIEW_MATCH';
    csvItemKey: string;
}
const viewMatchState = (csvItemKey: string): ViewMatchState => ({
    stateName: 'VIEW_MATCH',
    csvItemKey,
});

type MatchState = InactiveMatchState | SelectMatchState | ConfirmMatchState | ViewMatchState;

interface CsvHighlightMatchState {
    matchState: MatchState;
    matchMode: { state: 'ENABLED'; osmHighlightSetKey: string } | { state: 'DISABLED' };
}
function matchModeEnabledState(osmHighlightSetKey: string): CsvHighlightMatchState['matchMode'] {
    return { state: 'ENABLED', osmHighlightSetKey };
}

// Type assertion required for createSlice type inference to work
const INITIAL_STATE = {
    matchState: { stateName: 'INACTIVE' },
    matchMode: { state: 'DISABLED' },
} as CsvHighlightMatchState;

export const confirmMatch = createAction<{ csvItemKey: string; osmId: number }>(
    'csvHighlightMatch/confirmMatch'
);

export const deleteMatch = createAction<{ csvItemKey: string; extraId: string }>(
    'csvHighlightMatch/deleteMatch'
);

function isOneOf<S extends string>(validStates: S[], state: string): state is S {
    return (validStates as string[]).includes(state);
}
function restrictStates<S extends MatchState['stateName']>(
    states: S[],
    matchState: MatchState,
    thunk: (matchState: MatchState & { stateName: S }) => unknown
) {
    if (isOneOf(states, matchState.stateName)) {
        thunk(matchState as MatchState & { stateName: S });
    } else {
        logInvalidTransition(matchState.stateName, 'selectMatch');
    }
}
function logInvalidTransition(currentState: string, action: string) {
    console.error(
        `Invalid state transition (currentState: '${currentState}' ; action: '${action})'`
    );
}

const csvHighlightMatchSlice = createSlice({
    name: 'csvHighlightMatch',
    initialState: INITIAL_STATE,
    reducers: {
        startMatch(state, { payload: csvItemKey }: PayloadAction<string>) {
            state.matchState = { stateName: 'SELECT_MATCH', csvItemKey };
            state.matchMode = matchModeEnabledState(primaryLibraryItemKey(csvItemKey));
        },
        selectMatch(state, { payload: { osmId } }: PayloadAction<{ osmId: number }>) {
            restrictStates(['SELECT_MATCH', 'CONFIRM_MATCH'], state.matchState, (matchState) => {
                state.matchState = {
                    stateName: 'CONFIRM_MATCH',
                    csvItemKey: matchState.csvItemKey,
                    candidateMatchOsmId: osmId,
                };
            });
        },
        rejectMatch(state) {
            restrictStates(['CONFIRM_MATCH'], state.matchState, (matchState) => {
                state.matchState = {
                    stateName: 'SELECT_MATCH',
                    csvItemKey: matchState.csvItemKey,
                };
            });
        },
        exitCsvHighlightMatching(state) {
            state.matchState = INITIAL_STATE.matchState;
        },
        viewMatch(state, { payload: csvItemKey }: PayloadAction<string>) {
            state.matchState = viewMatchState(csvItemKey);
            state.matchMode = matchModeEnabledState(primaryLibraryItemKey(csvItemKey));
        },

        enterMatchMode(state, { payload }: PayloadAction<{ osmHighlightSetKey: string }>) {
            state.matchMode = { state: 'ENABLED', osmHighlightSetKey: payload.osmHighlightSetKey };
        },
        exitMatchMode() {
            return INITIAL_STATE;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(confirmMatch, (state, { payload }) => {
            const primaryKey = primaryLibraryItemKey(payload.csvItemKey);
            state.matchState = viewMatchState(`${primaryKey}--${payload.osmId}`);
        });
        builder.addCase(deleteMatch, (state, { payload }) => {
            const primaryKey = primaryLibraryItemKey(payload.csvItemKey);
            state.matchState = viewMatchState(`${primaryKey}--unmatched--${payload.extraId}`);
        });
        // Take the user out of match mode if any of the following actions occur
        builder.addMatcher(isAnyOf(clearPresentation, enterPresentationMode), () => INITIAL_STATE);
    },
});

export const {
    startMatch,
    selectMatch,
    rejectMatch,
    viewMatch,
    exitCsvHighlightMatching,
    enterMatchMode,
    exitMatchMode,
} = csvHighlightMatchSlice.actions;
export default csvHighlightMatchSlice.reducer;

export function selectShowCsvMatchLocationDrawer(state: RootState): boolean {
    return state.csvHighlightMatch.matchState.stateName !== 'INACTIVE';
}

export const selectCsvMatchState = (state: RootState) => state.csvHighlightMatch.matchState;

interface SelectMatchCsvInfoReturn {
    state: CsvHighlightMatchState['matchState']['stateName'];
    csvItemKey?: string;
    candidateMatchOsmId?: number;
}
export const selectMatchCsvInfo = createSelector(
    selectCsvMatchState,
    (matchState): SelectMatchCsvInfoReturn => {
        return {
            state: matchState.stateName,
            csvItemKey: 'csvItemKey' in matchState ? matchState.csvItemKey : undefined,
            candidateMatchOsmId:
                'candidateMatchOsmId' in matchState ? matchState.candidateMatchOsmId : undefined,
        };
    }
);

export const selectOsmHighlightInMatchMode = createSelector(
    (state: RootState) => state.csvHighlightMatch,
    (state) =>
        state.matchMode.state === 'ENABLED' ? state.matchMode.osmHighlightSetKey : undefined
);
