export const OSM_OVERPASS_API_ENDPOINT = 'https://overpass-api.de/api/interpreter';

interface OsmOpResultJson {
    elements: unknown;
}

interface GetOsmIdByLatLongResponse {
    elements: {
        type: string;
        id: number;
    }[];
}

export class OsmOpResult {
    private result: Partial<OsmOpResultJson>;
    constructor(result: NonNullable<unknown>) {
        this.result = result;
    }

    *elements(): IterableIterator<OsmOpElement> {
        if (!Array.isArray(this.result.elements)) {
            return;
        }
        for (const element of this.result.elements) {
            if (element) {
                yield new OsmOpElement(element);
            }
        }
    }
}

export interface OsmOpResultJsonElement {
    type: unknown;
    id: unknown;
    center: unknown;
    tags: unknown;
    bounds: unknown;
}

interface OsmPoint {
    lat: number;
    lon: number;
}

interface OsmBoundingBox {
    minlat?: unknown;
    maxlat?: unknown;
    minlon?: unknown;
    maxlon?: unknown;
}

interface BoundingBox {
    minLat: number;
    minLon: number;
    maxLat: number;
    maxLon: number;
}

export class OsmOpElement {
    constructor(private element: OsmOpResultJsonElement) {}

    get id(): number | undefined {
        return typeof this.element.id === 'number' ? this.element.id : undefined;
    }

    get type(): string | undefined {
        return typeof this.element.type === 'string' ? this.element.type : undefined;
    }

    get center(): OsmPoint | undefined {
        if (typeof this.element.center !== 'object' || this.element.center === null) {
            return undefined;
        }
        const center: Partial<OsmPoint> = this.element.center;
        return typeof center.lat === 'number' && typeof center.lon === 'number'
            ? { lat: center.lat, lon: center.lon }
            : undefined;
    }
    get bounds(): OsmBoundingBox | undefined {
        if (typeof this.element.bounds !== 'object' || this.element.bounds === null) {
            return undefined;
        }
        return this.element.bounds;
    }
    private _tags: Record<string, string | undefined> | undefined;
    get tags(): Record<string, string | undefined> {
        if (this._tags) {
            return this._tags;
        }
        this._tags = {};

        if (typeof this.element.tags !== 'object' || this.element.tags === null) {
            return this._tags;
        }

        for (const [key, value] of Object.entries(this.element.tags)) {
            this._tags[key] = value;
        }

        return this._tags;
    }

    resetTags(): void {
        this._tags = undefined;
    }

    getApproxBBox(halfWidth: number): BoundingBox | undefined {
        const center = this.center;
        if (!center) {
            return undefined;
        }

        const latCos = Math.cos((center.lat * Math.PI) / 180);
        const halfWidthLatDeg = halfWidth / (111_111 * latCos);
        const halfWidthLonDeg = halfWidth / 111_111;

        return {
            minLat: center.lat - halfWidthLatDeg,
            minLon: center.lon - halfWidthLonDeg,
            maxLat: center.lat + halfWidthLatDeg,
            maxLon: center.lon + halfWidthLonDeg,
        };
    }

    getElement(): OsmOpResultJsonElement {
        return this.element;
    }
}

export function inBoundingBox(bbox: BoundingBox, point: OsmPoint): boolean {
    return (
        bbox.minLat <= point.lat &&
        bbox.maxLat >= point.lat &&
        bbox.minLon <= point.lon &&
        bbox.maxLon >= point.lon
    );
}

interface SurroundingPointsEntry {
    latitude: number | undefined;
    longitude: number | undefined;
}
export async function getSurroundingPoints(rows: SurroundingPointsEntry[]): Promise<string> {
    const latLongs = rows
        .map((row): [number | undefined, number | undefined] => [row.latitude, row.longitude])
        .filter((latLong) => Number.isFinite(latLong[0]) && Number.isFinite(latLong[1]))
        .flat()
        .join(',');

    const query = `
    [out:json];
    (
      way[building]["addr:housenumber"]["addr:street"](around:500,${latLongs});
      rel[building]["addr:housenumber"]["addr:street"](around:500,${latLongs});
      node["addr:housenumber"]["addr:street"](around:500,${latLongs});
    );
    out tags center qt;
    `;

    const response = await fetch(
        `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`
    );
    return await response.json();
}

export async function getOsmIdByLatLong({
    lat,
    lon,
}: OsmPoint): Promise<GetOsmIdByLatLongResponse> {
    const query = `
        [out:json];
            way(around:50, ${lat}, ${lon})["building"];
        out ids;
    `;

    const response = await fetch(
        `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`
    );
    return await response.json();
}

export async function getContainingBuildings(nodes: OsmOpElement[]): Promise<OsmOpResult> {
    const query = `
    [out:json];

    node(id:${nodes.map((r) => r.id)}) -> .ns;
    foreach.ns->.n
    {
      .n is_in -> .a;
      (
        way[building](pivot.a);
        rel[building](pivot.a);
      );
      convert _result ::=::,::id=id(),_old_type=type(),_contains_node_id=n.set(id());
      out;
    }
    `;
    const response = await fetch(
        `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`
    );
    const isInResults = new OsmOpResult(await response.json());

    if (isInResults && isInResults.elements && Array.isArray(isInResults.elements)) {
        return new OsmOpResult({
            ...isInResults,
            elements: [...isInResults.elements()].map((el) => ({
                ...el.getElement(),
                type: el.tags?.['_old_type'],
            })),
        });
    }
    return isInResults;
}
