import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

export enum RightPanelKeys {
    Search = 'search',
    Spreadsheet = 'spreadsheet',
    HighlightSet = 'highlight_set',
    Image = 'image',
    Website = 'website',
    MapIt = 'map_it',
    Submarkets = 'submarkets',
    SubmarketStatistics = 'submarket_statistics',
    MarketViews = 'market_views',
    QuickLayer = 'quick_layer',
    QuickLayerFromLibrary = 'quick_layer_from_library',
    SitePlans = 'site_plans',
    Assistant = 'assistant',
    WeatherWidget = 'weather_widget',
    LineOfSightWidget = 'line_of_sight_widget',
    DaylightWidget = 'daylight_widget',
}

export const RightPanelLabels = {
    [RightPanelKeys.Search]: 'Search',
    [RightPanelKeys.Spreadsheet]: 'Spreadsheet',
    [RightPanelKeys.HighlightSet]: 'Highlight Set',
    [RightPanelKeys.Image]: 'Image',
    [RightPanelKeys.Website]: 'Website',
    [RightPanelKeys.MapIt]: 'MapIt',
    [RightPanelKeys.Submarkets]: 'Submarkets',
    [RightPanelKeys.MarketViews]: 'Market Views',
    [RightPanelKeys.SubmarketStatistics]: 'Submarket Statistics',
    [RightPanelKeys.QuickLayer]: 'Quick Layer',
    [RightPanelKeys.QuickLayerFromLibrary]: 'Quick Layer from Library',
    [RightPanelKeys.SitePlans]: 'Site Plans',
    [RightPanelKeys.Assistant]: 'Assistant',
    [RightPanelKeys.WeatherWidget]: 'Weather Widget',
    [RightPanelKeys.LineOfSightWidget]: 'Line of Sight Widget',
    [RightPanelKeys.DaylightWidget]: 'Daylight Widget',
};

export interface RightPanelItem {
    name: RightPanelKeys;
    visibleInPresentationMode?: boolean;
    hiddenInClientShare?: boolean;
    isHalfHeight?: boolean;
}

const rightPanels: {
    [key in RightPanelKeys]: RightPanelItem;
} = {
    [RightPanelKeys.Search]: {
        name: RightPanelKeys.Search,
    },
    [RightPanelKeys.Spreadsheet]: {
        name: RightPanelKeys.Spreadsheet,
    },
    [RightPanelKeys.HighlightSet]: {
        name: RightPanelKeys.HighlightSet,
    },
    [RightPanelKeys.Image]: {
        name: RightPanelKeys.Image,
    },
    [RightPanelKeys.Website]: {
        name: RightPanelKeys.Website,
    },
    [RightPanelKeys.MapIt]: {
        name: RightPanelKeys.MapIt,
    },
    [RightPanelKeys.Submarkets]: {
        name: RightPanelKeys.Submarkets,
    },
    [RightPanelKeys.SubmarketStatistics]: {
        name: RightPanelKeys.SubmarketStatistics,
        visibleInPresentationMode: true,
    },
    [RightPanelKeys.MarketViews]: {
        name: RightPanelKeys.MarketViews,
        isHalfHeight: true,
    },
    [RightPanelKeys.QuickLayer]: {
        name: RightPanelKeys.QuickLayer,
    },
    [RightPanelKeys.QuickLayerFromLibrary]: {
        name: RightPanelKeys.QuickLayerFromLibrary,
    },
    [RightPanelKeys.SitePlans]: {
        name: RightPanelKeys.SitePlans,
    },
    [RightPanelKeys.Assistant]: {
        name: RightPanelKeys.Assistant,
    },
    [RightPanelKeys.WeatherWidget]: {
        name: RightPanelKeys.WeatherWidget,
        isHalfHeight: true,
    },
    [RightPanelKeys.LineOfSightWidget]: {
        name: RightPanelKeys.LineOfSightWidget,
        isHalfHeight: true,
    },
    [RightPanelKeys.DaylightWidget]: {
        name: RightPanelKeys.DaylightWidget,
        isHalfHeight: true,
    },
};

interface RightPanelState {
    activePanel: RightPanelItem | null;
}

const initialState: RightPanelState = {
    activePanel: null,
};

export const rightPanelSlice = createSlice({
    name: 'rightPanel',
    initialState,
    reducers: {
        setActiveRightPanel: (state, action: PayloadAction<RightPanelKeys>) => {
            const panel = rightPanels[action.payload];
            state.activePanel = panel;
        },

        toggleActiveRightPanel: (state, action: PayloadAction<RightPanelKeys>) => {
            const panel = rightPanels[action.payload];
            state.activePanel = state.activePanel?.name === panel.name ? null : panel;
        },

        clearRightPanel: (state) => {
            state.activePanel = null;
        },
    },
});

export const { setActiveRightPanel, clearRightPanel, toggleActiveRightPanel } =
    rightPanelSlice.actions;

export const selectActiveRightPanel = (state: RootState): RightPanelItem | null =>
    state.rightPanel.activePanel;

export const getActiveRightPanelKey = (state: RootState): RightPanelKeys | null =>
    state.rightPanel.activePanel?.name ?? null;

export default rightPanelSlice.reducer;
