import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

interface AssistantPanelState {
    showAssistant: boolean;
    apiKey: string;
    apiUrl: string;
    model: string;
}

export enum OpenAiModels {
    GPT_3_5_TURBO = 'gpt-35-turbo',
    GPT_4 = 'gpt-4-preview',
    GPT_4O = 'gpt-4o',
}

const LOCAL_STORAGE_KEYS = {
    API_KEY: 'blackbird-assistant-api-key',
    API_URL: 'blackbird-assistant-api-url',
    MODEL: 'blackbird-assistant-model',
};

const initialState: AssistantPanelState = {
    showAssistant: false,
    apiKey: localStorage.getItem(LOCAL_STORAGE_KEYS.API_KEY) || '',
    apiUrl:
        localStorage.getItem(LOCAL_STORAGE_KEYS.API_URL) ||
        'https://blackbird-sandbox-openai.openai.azure.com/',
    model: localStorage.getItem(LOCAL_STORAGE_KEYS.MODEL) || OpenAiModels.GPT_4O,
};

const assistantSlice = createSlice({
    name: 'assistant',
    initialState,
    reducers: {
        loadFromLocalStorage: (state) => {
            state.apiKey = localStorage.getItem(LOCAL_STORAGE_KEYS.API_KEY) || state.apiKey;
            state.apiUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.API_URL) || state.apiUrl;
            state.model = localStorage.getItem(LOCAL_STORAGE_KEYS.MODEL) || state.model;
        },
        setApiKey: (state, action: { payload: string }) => {
            state.apiKey = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.API_KEY, action.payload);
        },
        setApiUrl: (state, action: { payload: string }) => {
            state.apiUrl = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.API_URL, action.payload);
        },
        setModel: (state, action: { payload: string }) => {
            state.model = action.payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.MODEL, action.payload);
        },
    },
});

export const { loadFromLocalStorage, setApiKey, setApiUrl, setModel } = assistantSlice.actions;

export const selectApiKey = (state: RootState) => state.assistant.apiKey;
export const selectApiUrl = (state: RootState) => state.assistant.apiUrl;
export const selectModel = (state: RootState) => state.assistant.model;

export default assistantSlice.reducer;
