import { isNil } from 'lodash';

import { Slide } from 'components/Slides/SlideTypes';
import { parseCameraSettings } from 'helpers/cameraHelper';
import { quickLayerButtonKeySuffix } from 'helpers/quickLayerHelper';
import { AppThunk } from 'store';
import { setBasemapId } from 'store/basemapSlice';
import {
    FeatureStates,
    setFeatureStates,
    setLegendPlacement,
    setLegendVisible,
} from 'store/featuresSlice';
import {
    updateCheckedKeys,
    updateLibraryItemActiveState,
    updateLibraryItemLegendState,
} from 'store/libraryItemSlice';
import { setStreetViewState } from 'store/streetMapViewSlice';
import { GoToTarget3DOptions } from 'types/GoToTarget3DOptions';
import { getLegendVisibility } from '../helpers/slideHelper';
import { assignDaylightState } from './daylightWidgetSlice';
import { setActiveSlide } from './slideSlice';
import { defaultWeatherSettings, setWeatherState } from './weatherWidgetSlice';

export const activateSlide = (
    slide: Slide,
    setGoTo: (options: GoToTarget3DOptions) => void
): AppThunk<void> => {
    return (dispatch) => {
        if (isNil(slide.state)) return;
        const state = slide.state;

        // TODO: camera state should be parsed on slide load and creation.
        if (state?.camera) setGoTo(parseCameraSettings(state.camera));

        if (state?.basemap) dispatch(setBasemapId(state.basemap));

        if (state?.mapFeatures) dispatch(setFeatureStates(state.mapFeatures as FeatureStates));
        dispatch(setLegendVisible(getLegendVisibility(state.legend)));
        dispatch(setLegendPlacement(state.legend?.placement ?? 'BOTTOM-RIGHT'));
        dispatch(setStreetViewState(slide.state.streetView));

        dispatch(
            assignDaylightState({
                ...state.daylightView,
                isInstant: true,
            })
        );
        dispatch(
            setWeatherState({
                state: state.weather ?? defaultWeatherSettings,
                isInstant: true,
            })
        );

        const filteredLayerIds =
            state.layerIds?.filter((layerId) => !layerId.includes(quickLayerButtonKeySuffix)) ?? [];

        dispatch(updateCheckedKeys(filteredLayerIds ?? []));
        dispatch(updateLibraryItemActiveState(filteredLayerIds ?? []));
        dispatch(updateLibraryItemLegendState(state.legend?.openKeys ?? []));
        dispatch(setActiveSlide(slide));
    };
};
