import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { parseCsv } from 'helpers/csvLayerHelper';
import endpoints from 'utils/apiClient/endpoints';

export const csvLayerApi = createApi({
    reducerPath: 'csvLayerApi',
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        getCsvData: builder.query({
            queryFn: async (id: number) => {
                const csvUrlResponse = await endpoints.csv.signedUrl.get({
                    templateValues: {
                        id,
                    },
                });

                const { url } = await csvUrlResponse.json();
                const csvDataResponse = await fetch(url);

                const csvData = await csvDataResponse.text();
                const parsedCsv = await parseCsv(csvData);

                return { data: parsedCsv.data };
            },
        }),
    }),
});

export const { useGetCsvDataQuery } = csvLayerApi;
