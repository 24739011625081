import { ReactNode } from 'react';

import { AppDispatch } from 'store';
import AmenityLayerMetadata from './AmenityLayerMetadata';
import { CsvLayerMetadata } from './CsvLayerMetadata';
import { HighlightSetMetadata } from './HighlightSetMetadata';
import { ImageOverlayMetadata, KMLScreenOverlay } from './ImageLayerMetadata';
import KmlLayerMetadata, { LayerPlacemark } from './KmlLayerMetadata';
import MapItLayerMetadata from './MapItLayerMetadata';
import { MarketViewLayerMetadata, MarketViewMetadata } from './MarketViewLayerMetadata';
import { MediaLayerMetadata, SitePlanMetadata } from './MediaLayerMetadata';
import { OsmHighlightSetMetadata } from './OsmHighlightSetMetadata';
import { AnnotationMetadata, QuickLayerMetadata } from './QuickLayerMetadata';
import { SubmarketStatisticsMetadata } from './SubmarketStatisticsMetadata';
import UnpinnedControllerMetadata from './UnpinnedControllerMetadata';
import { WebsiteOverlayMetadata } from './WebsiteOverlayMetadata';
import { WidgetLayerMetadata } from './WidgetLayerMetadata';

export interface SaveSearchMetadata {
    name: string;
    searchParameters: {
        bronzeSourceSystem: string;
        input: string;
    };
    searchTypeId: number | undefined;
}

export type LibraryLayerMetaData =
    | ImageOverlayMetadata
    | SaveSearchMetadata
    | KmlLayerMetadata
    | AmenityLayerMetadata
    | MapItLayerMetadata
    | CsvLayerMetadata
    | MarketViewMetadata
    | UnpinnedControllerMetadata
    | WebsiteOverlayMetadata
    | HighlightSetMetadata
    | OsmHighlightSetMetadata
    | QuickLayerMetadata
    | WidgetLayerMetadata
    | SubmarketStatisticsMetadata
    | MediaLayerMetadata;

export interface LibraryLayerTreeItemBase {
    id: number;
    key: string;
    title: string;
    ownerId?: string;
    checked?: boolean;
    children?: LibraryLayerTreeItem[];
    showTotalTag?: boolean;
    ignoreInTotal?: boolean;
    isLeaf?: boolean;
    error?: string;
    disableCheckbox?: boolean;
    active?: boolean;
    legendEnabled?: boolean;
    checkable?: boolean;
    prefixElement?: (
        dispatch: AppDispatch,
        libraryItem: LibraryLayerTreeItem,
        index?: number
    ) => ReactNode;
}

export interface FolderTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'Folder';
}

export interface KmlLayerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'Layer' | 'KmlLayer';
    metadata: KmlLayerMetadata | KMLScreenOverlay | LayerPlacemark;
}

export interface CsvLayerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'CsvLayer';
    metadata?: CsvLayerMetadata;
}

export interface ImageOverlayTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'Image';
    metadata: ImageOverlayMetadata;
}

export interface MapItLayerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'MapItLayer';
    metadata: MapItLayerMetadata;
}

export interface HighlightSetTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'PinnedProperties';
    metadata: HighlightSetMetadata | undefined;
}

export interface UnpinnedTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'UnpinnedController';
}

export interface MarketViewTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'PropertyView';
    metadata: MarketViewLayerMetadata | undefined;
}

export interface SavedSearchTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'PropertySearch';
    metadata: SaveSearchMetadata;
}

export interface SubmarketStatisticsTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'SubmarketStatistics';
    metadata: SubmarketStatisticsMetadata;
}

export interface AmenityTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'Amenity';
    metadata: AmenityLayerMetadata;
}

export interface WebsiteOverlayTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'Overlay';
    metadata: WebsiteOverlayMetadata;
}

export interface OsmHighlightSetTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'OsmHighlightSet';
    metadata: OsmHighlightSetMetadata;
}

export interface QuickLayerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'QuickLayer';
    isActionItem?: boolean;
    metadata?: QuickLayerMetadata | AnnotationMetadata;
}

export interface WidgetLayerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'WidgetLayer';
    metadata: WidgetLayerMetadata;
}

export interface UnpinnedControllerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'UnpinnedController';
}

export interface MediaLayerTreeItem extends LibraryLayerTreeItemBase {
    itemType: 'MediaLayer';
    metadata?: MediaLayerMetadata | SitePlanMetadata;
}

export type LibraryLayerTreeItem =
    | FolderTreeItem
    | KmlLayerTreeItem
    | CsvLayerTreeItem
    | ImageOverlayTreeItem
    | MapItLayerTreeItem
    | HighlightSetTreeItem
    | UnpinnedTreeItem
    | MarketViewTreeItem
    | SavedSearchTreeItem
    | SubmarketStatisticsTreeItem
    | AmenityTreeItem
    | WebsiteOverlayTreeItem
    | OsmHighlightSetTreeItem
    | QuickLayerTreeItem
    | WidgetLayerTreeItem
    | UnpinnedControllerTreeItem
    | MediaLayerTreeItem;

export type LibraryLayerTreeItemWithMetadata = LibraryLayerTreeItem & { metadata?: unknown };

export default LibraryLayerTreeItem;

export function isTreeItemOfType<A extends Array<LibraryLayerTreeItem['itemType']>>(
    libraryItem: LibraryLayerTreeItem,
    itemTypes: A
): libraryItem is LibraryLayerTreeItem & { itemType: A[number] } {
    return itemTypes.includes(libraryItem.itemType);
}

/**
 * A utility function to find the key of the primary containing library item.
 *
 * There are two types of library items: primary and secondary,
 * where secondary library items are the children of primary library items.
 *
 * Primary items have the format `` `${itemType}--${uid}` ``,
 * and secondary items have the format of `` `${primaryItemKey}--${rest}` ``.
 *
 * This function extracts the primary item key from a primary or secondary item key.
 *
 * @param libraryItemKey The key of library item to find the parent key of
 * @returns The key of the main containing library item
 */
export function primaryLibraryItemKey(libraryItemKey: string): string {
    return libraryItemKey.split('--').slice(0, 2).join('--');
}

export function forEachInTree(
    tree: LibraryLayerTreeItem,
    thunk: (item: LibraryLayerTreeItem) => void
): void {
    thunk(tree);
    if (!tree.children) {
        return;
    }
    for (let i = 0; i < tree.children.length; i++) {
        forEachInTree(tree.children[i], thunk);
    }
}

/**
 * Finds all the `key` values in the tree represented by a node and all
 * its descendants
 * @param tree The tree to search for keys
 * @returns A set of all the keys for the node found in the tree
 */
export function keysInTree(tree: LibraryLayerTreeItem): Set<string> {
    const keySet = new Set<string>();

    if (tree.children) {
        forEachInTree(tree, (node) => keySet.add(node.key));
    }

    return keySet;
}
