import { datadogRum } from '@datadog/browser-rum';

import config from 'utils/config';

const init = () => {
    datadogRum.init({
        applicationId: `${config.datadogApplicationId}`,
        clientToken: `${config.datadogClientToken}`,
        site: 'us3.datadoghq.com',
        service: `${config.datadogServiceName}`,
        env: `${config.datadogEnvironment}`,
        version: `${window.VERSION}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
};

export const trackBlackbirdAction = (actionName: string, metadata: { feature: string }) => {
    datadogRum.addAction(actionName, {
        type: 'button-click',
        ...metadata,
    });
};

export default {
    init,
};
