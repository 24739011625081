/* eslint-disable @typescript-eslint/no-explicit-any -- Legacy use of any */

import {
    ListOfMarketHierarchyParameterInput,
    ListOfMarketPropertyTypeFilterInput,
    MarketHierarchyInput,
    MarketHierarchyParameterInput,
    MarketPropertyTypeInput,
} from 'types/CspInputs';

export interface MarketHierarchyProps {
    marketLevel1: string;
}

export interface MarketSubmarketHierarchyProps {
    marketLevel1: string;
    marketLevel2: string;
}

export interface MarketSearch {
    market: string;
    submarket: string;
}

export const createMarketQuery = (marketNames: Array<string>, propertyTypes: Array<string>) => {
    let marketQuery = '';
    if (marketNames?.length) {
        marketNames.forEach(function (item, index) {
            marketQuery = marketQuery + `LOWER(MarketName) like '%${item.toLowerCase()}%'`;
            if (index < marketNames.length - 1) marketQuery = marketQuery + ' OR ';
        });
    }
    if (propertyTypes?.length) {
        let propertyQuery = '';
        propertyTypes.forEach(function (item, index) {
            propertyQuery = propertyQuery + `LOWER(PropType) like '%${item.toLowerCase()}%'`;
            if (index < propertyTypes.length - 1) propertyQuery = propertyQuery + ' OR ';
        });
        marketQuery = `${marketQuery} AND ${propertyQuery}`;
    }
    return marketQuery;
};

const buildMarketSubmarketHierarchy = (
    markets: Array<string>,
    submarkets: Array<string>
): MarketHierarchyInput[] => {
    const marketHierarchy = Array<MarketHierarchyInput>();
    markets.forEach((item) => {
        submarkets.forEach((submarket) => {
            const submarketName = JSON.parse(submarket);
            marketHierarchy.push({
                marketLevel1: item,
                marketLevel2: submarketName.submarket,
            } as MarketHierarchyInput);
        });
    });
    return marketHierarchy;
};

export const createCSPMarketSubmarketQuery = (
    markets: Array<string>,
    submarkets: Array<string>
): ListOfMarketHierarchyParameterInput | null => {
    if (!markets || !submarkets) return null;
    if (markets.length && !submarkets.length) return createCSPMarketQuery(markets);
    const hierarchy = buildMarketSubmarketHierarchy(markets, submarkets);
    return {
        values: hierarchy,
    };
};

export const createCSPMarketQuery = (
    markets: Array<string>
): ListOfMarketHierarchyParameterInput => {
    const hierarchy = markets.map((item) => {
        return { marketLevel1: item } as MarketHierarchyParameterInput;
    });
    return {
        values: hierarchy,
    };
};

export const createMarketPropertyTypeQuery = (
    markets: Array<string>,
    propertyTypes: any
): ListOfMarketPropertyTypeFilterInput => {
    const hierarchy = markets.map((item) => {
        return {
            marketHierarchy: { marketLevel1: item },
            propertyType: propertyTypes.join(','),
        } as MarketPropertyTypeInput;
    });
    return {
        values: hierarchy,
    };
};

export const createMarketPropertyTypeHierarchy = (
    markets: Array<string>,
    submarkets: Array<string>,
    submarketClusters: Array<string>
): ListOfMarketHierarchyParameterInput => {
    const collections = [
        {
            name: 'marketLevel1',
            items: markets,
        },
    ];
    if (submarkets.length) {
        collections.push({ name: 'marketLevel2', items: submarkets });
    }
    if (submarketClusters.length) {
        collections.push({ name: 'subMarketCluster', items: submarketClusters });
    }
    return {
        values: getCombinations(collections),
    };
};

const getCombinations = (list: any, i = 0, result = [] as any[], current = {}) => {
    if (i === list.length) result.push(current);
    else {
        const items = list[i].items;
        items.forEach((item: string) => {
            getCombinations(list, i + 1, result, { ...current, [list[i].name]: item });
        });
    }
    return result;
};
